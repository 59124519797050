@font-face {
  font-family: 'BwModelica';
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Regular.eot'); 
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Regular.eot#iefix') format('embedded-opentype'), 
       url('assets/fonts/bw_modelica/woff/BwModelica-Regular.woff') format('woff'); 
  font-weight: normal; 
}

@font-face {
  font-family: 'BwModelica';
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Bold.eot'); 
  src: url('assets/fonts/bw_modelica/eot/BwModelica-Bold.eot#iefix') format('embedded-opentype'), 
       url('assets/fonts/bw_modelica/woff/BwModelica-Bold.woff') format('woff'); 
  font-weight: bold; 
}

@font-face {
  font-family: 'BwModelica';
  src: url('assets/fonts/bw_modelica/eot/BwModelica-ExtraBold.eot'); 
  src: url('assets/fonts/bw_modelica/eot/BwModelica-ExtraBold.eot#iefix') format('embedded-opentype'), 
       url('assets/fonts/bw_modelica/woff/BwModelica-ExtraBold.woff') format('woff'); 
  font-weight: 800; 
}

*{
  box-sizing: border-box;
}


body{
  overflow-x:hidden; /* Contetti needs this */
  font-family: 'BwModelica';
  font-weight: normal;
  line-height: 1.4375;
  font-size: 16px;
  color:#000;
  background: #f6f6f6;
}


strong{
  font-weight: 800;
}


img{
  max-width: 100%;
  height: auto;
}

.app{
  margin:0 auto;
  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: visible;
}


.app-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.app-header h2{
  margin-top: 0px;
  margin-bottom: 0px;
}

.navbutton--left span{
  padding-left: 7px;
}


.app-header__center{
  width: 75%;
  padding-right: 20px;
  padding-left: 20px;
}

.app-header__left,
.app-header__right{
  width: 20%;
}

.navbutton{
  font-family: 'BwModelica';
  font-size: 14px;
  font-weight: normal;
  color:#000;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}



.cancel-content{
  padding: 0;
  text-align: right;
  font-size: 14px;
}

.cancel-content a{
  text-decoration: none;
  color: currentColor;
  font-weight: normal;
}

.app-inner{
  text-align: center;
  padding: 20px 20px 30px 20px;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);


  overflow: visible;
}

.app-bottom{
  padding-top:30px;
  margin-top:auto;
}

.app-loader{
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-bottom:20vh;
}

button,
a.button{
  font-family: 'BwModelica';
}

h1{
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  margin-bottom:20px;
}

h2{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.45;
  margin-bottom:20px;
}

h2,h3,h4,p{
  margin-top:10px;
  margin-bottom: 20px;
}





/* Buttons */

.button,
.option-form button,
a.button{
  background: #FFFFFF;
  /* border: 3px solid #02DF8F; */
  border: 3px solid #fff;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 12px;
  color:#000;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding:13px 40px;
  width: 100%;
  /*filter: drop-shadow(0px 10px 21px rgba(0, 0, 0, 0.2)); */
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

a.button{
  text-decoration: none;
  display: block;
}

.button.selected,
button.selected{
  border-color: #02DF8F;
}

.button--submit,
a.button--submit{
  background: #4B36CC;
  color:#fff;
  border-color:#4B36CC;
}

.button--black,
a.button--black,
.option-form .button--black{
  background: #000;
  color:#fff;
  border-color:#000;
}


.button--orange,
a.button--orange,
.option-form .button--orange{
  background: #FF5722;
  color:#fff;
  border-color: #FF5722;
}
.option-form .button--transparent,
.button--transparent,
a.button--transparent{
  border-color:#000;
  background: transparent;
  border-width: 1px;
  color:#000;
  box-shadow: none;
}

.button--margintop,
a.button--margintop{
  margin-top: 10px;
}

.button--marginbottom,
a.button--marginbottom{
  margin-top: 10px;
}

/* Start */

.instructions dt div{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color:#fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content:center ;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
  margin-bottom:10px;
}

.instructions dd{
  font-weight: bold;
  font-size: 23px;
  line-height: 1.39;
  margin-bottom:25px;
}

@media screen and ( max-height: 520px ){
  
  .instructions dt div{
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin-bottom:5px;
  }

  .instructions dd{
    font-weight: bold;
    font-size: 18px;
    margin-bottom:15px;
  }

}

/* Hints */

.hint{
  display: flex;
  line-height: 1px;
  margin-top:70px;
}

.hint__item--no{
  margin-right: 7px;
}


.hint__item--yes{
  margin-left: 7px;
}

.hint__item{
  position: relative;
}

.hint__symbol{
  position: absolute;
  right: 7px;
  bottom:8px;
}




/* Select screens */

.pin-visible,

.pin-visible{
  pointer-events: none;
}

.option-form button{
  margin-bottom:10px;
}

.option-form--icons button{
  width: initial;
  margin-right: 5px;
  margin-left: 5px;
  width: calc(33.33% - 10px);
  border-radius: 6px;
  padding-left:10px;
  padding-right: 10px;
  text-align: center;
  letter-spacing: 0;
  font-size: 10px;
}

.option-form--icons button img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.option-form--images{
  display: flex;
  flex-wrap: wrap;
}

.option-form--images button{
  height: auto;
  width: initial;
  background:transparent;
  margin-right: 5px;
  margin-left: 5px;
  width: calc(50% - 10px);
  border: 3px solid transparent;
  border-radius: 6px;
  padding:0;
  line-height: 1px;
  text-align: center;
  letter-spacing: 0;
  font-size: 10px;
  filter:none;
  box-shadow:none;
  border-radius: 0;
}



.option-form--images button img{
  max-width: 100%;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.option-form--images button.selected img{
  border: 3px solid #02DF8F;

}

.textarea{
  background: #FFFFFF;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  height:130px;
  width: 100%;
}

textarea.error,
input.error{
  border-color:red !important;
}

/* Consultation form */

.consultation-form input[type=email],
.consultation-form textarea{
  background: #FFFFFF;
  border: 1px solid #B7B7B7;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
}

.consultation-form input[type=email]{
  width: 100%;
  padding:13px 20px;
}

/* Results */

.app-inner--results{
  padding-bottom: 170px;
}

.pin-invisible{
  display: none;
}

.pin-visible>span,
.pin-invisible > span{
  /*filter: drop-shadow(0px 10px 21px rgba(0, 0, 0, 0.2)); */
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

.results-cta{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #D3D3D3;
  padding: 15px 20px 30px 20px;
  transition:opacity 1s;
}

.results-cta--hidden{
  opacity: 0;
  pointer-events: none;
}

.results-cta label{
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-bottom:10px;
}

.results-cta__buttons{

  display: flex;
  justify-content: space-between;
  max-width: 285px;
  margin-left: auto;
  margin-right: auto;
}


.results-cta .button-yes,
.results-cta .button-no{
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 800;
  background:none;
  border:none;
  padding:10px 20px;
}

.results-cta .button-yes{
  color:#02DF8F;
}

.results-cta .button-no{
  color:#FF715E;
  
}

/* Share */

.button--invite{
  margin-bottom:10px;
}


.share-results{
  overflow: hidden;
  transition: max-height 2s ease-in;
  max-height: 0px;
  opacity:0;
  padding-bottom:0px;
}

.share-results--visible{
  max-height: 9999px;
  opacity:1;
  padding-bottom:20px;

  /* Fix for drop shadows being cut off in overflow hidden */

  padding: 0 50px 50px 50px;
  margin:0px -50px -30px -50px;

}

/* Feedback form */

.feedback-form{
  text-align: center;
  padding: 20px 20px 30px 20px;
  display: flex;
  flex-flow: column nowrap;
  overflow: visible;
}

.feedback-form input[type=email],
.feedback-form textarea{
  background: #FFFFFF;
  border: 1px solid #B7B7B7;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
}


.feedback-form input[type=email]{

  padding:13px 20px;
}

.feedback-form textarea{
  min-height: 70px;
 
}



.submit-form__label{
  font-size: 10px;
  text-transform: uppercase;
  margin-top:20px;
  margin-bottom:20px;
}

/* Search */

.city-input{
  background: #FFFFFF;
  border-radius: 54px;
  width: 100%;
  font-size: 14px;
  font-family: 'BwModelica';
  filter: drop-shadow(0px 1px 21px rgba(0, 0, 0, 0.16));
  border:0;
  padding:15px 20px 15px 50px;
  background-repeat: no-repeat;
  background-position: 20px center;


}

.city-input::placeholder{
  color: #777777;
}

.city-input:focus{
  outline: none;
  
}

.city-suggestions{
  font-weight: 700;
  font-size: 14px;
  margin-top:20px;
}

.city-suggestions a{
  color:#000;
  text-decoration: none;
}

.city-suggestions ul li{
  text-align: left;
  border-bottom: 1px solid #D3D3D3;
  padding:15px;
}

/* City */

.service-tags-wrap{
  overflow-x: scroll;
  overflow-y:hidden;
  margin-bottom:10px;
}

.service-tags{
  display: flex;
}

.service-tags button{
  width: auto;
  white-space: nowrap;
  margin-right: 10px;
  color:000;
  background: transparent;
  font-weight: normal;
  font-size: 12px;
  font-family: 'BwModelica';
  border:1px solid #000;
  border-radius: 0;
  padding:5px;
  cursor: pointer;
}

.service-tags button.selected{
  background: #000;
  color:#fff;
}

.companies-grid{
  display: flex;
  flex-wrap: wrap;
}

.companies-grid__item{
  width: 50%;
}

.companies-grid__item a{
  color:#000;
  text-decoration: none;
}

.companies-grid__item:nth-child(odd){
  padding-right:5px;
}

.companies-grid__item:nth-child(even){
  padding-left:5px;
}

.companies-grid__item h3{
  font-weight: 700;
  font-size: 15px;  
}

.companies-grid__thumb{
  position: relative;
  line-height: 1px;
}

.companies-grid__thumb .playbutton{
  position: absolute;
  left: 10px;
  bottom:10px;
  pointer-events: none;
}

.companies-grid__thumb .thumb{
  width: 100%;

}

.companies-grid__thumb .rounded{
  border-radius: 13px;
}

/* Company */

.company-video{
  filter: drop-shadow(0px 11px 29px rgba(0, 0, 0, 0.35));
}

.company-details{
  margin:15px 0;
}

.company-details__row a{
  text-decoration: none;
  color:#000;
}

.company-details__row{
  padding:8px 0;
  font-size: 14px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.company-details__row img{
  margin-right: 12px;
}

/* Company media */

.company-video,
.company-image{
  filter: drop-shadow(0px 11px 29px rgba(0, 0, 0, 0.35));
}

.company-video{
  position: relative;
  overflow: hidden;
}


.company-video__poster{
  position: absolute;
  left: 0;
  right: 0;
  bottom:0;
  top:0;
  cursor: pointer;
}

.company-video__poster .poster-img{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: 50% 50%;
}

.company-video__poster .playbutton{
  position: absolute;
  left: 50%;
  top:50%;
  margin-left: -48px;
  margin-top:-48px;
}


.company-video-alt{
  
}

.company-video-alt .company-video__poster{
  position: relative;

  cursor: pointer;
}
